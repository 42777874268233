@import "../../scss/index.scss";

.button-container {
  // min-height: 20vh;
  margin-bottom: 10px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff; // $background-grey-light;
  // margin: 20px;
}

.form-search-wrapper {
  box-shadow: 0 0 20px 0 rgba(255, 255, 255);
  margin-bottom: 10px;
}
// .form-search {
//   max-width: 1170;
//   margin-left: auto;
//   margin-right: auto;
//   // padding: 1.5em;
// }

.form-search-wrapper > * {
  padding: 1em;
}

.form-search {
  background-color: rgba(197, 192, 192, 0.849);
}
.form-search form {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  column-gap: 1em;
}

.block {
  margin-bottom: 10px;
  grid-column: 1/5;
}

.form_search__input {
  // margin: 5px;
  .ant-select,
  .ant-picker-input {
    width: 100%;
  }
}

.input-buscar {
  position: relative;
  height: 60px;
  // max-width: 900px;
  width: 100%;
  background: #fff;
  margin: 0 5px;
  border-radius: 8px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
}
.input-buscar i,
.input-buscar .btn-buscar {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
.input-buscar i {
  left: 20px;
  font-size: 30px;
  color: #707070;
}
.input-buscar input {
  height: 100%;
  width: 100%;
  outline: none;
  font-size: 18px;
  font-weight: 400;
  border: none;
  padding: 0 155px 0 65px;
  border: 2px solid #4070f4;
  background-color: transparent;
}
.input-buscar .btn-buscar {
  right: 20px;
  font-size: 16px;
  font-weight: 400;
  color: #fff;
  border: none;
  padding: 12px 30px;
  border-radius: 6px;
  background-color: #4070f4;
  cursor: pointer;
}
.input-buscar .btn-buscar:active {
  transform: translateY(-50%) scale(0.98);
}

@media screen and (max-width: 991px) {
}

@media screen and (max-width: 768px) {
}
@media screen and (max-width: 496px) {
  .form-search form {
    display: grid;
    grid-template-columns: 2fr 2fr;
    grid-template-rows: auto;
    column-gap: 1em;
    row-gap: 1em;
  }

  .form_search__input {
    // margin: 5px;
    grid-column: 1 / 6;
    .ant-select,
    .ant-picker-input {
      width: 100%;
    }
  }

  .button-container {
    min-height: 10vh;
    width: 100%;
    margin-bottom: 10px;
  }

  .input-buscar {
    height: 66px;
    margin: 0 5px;
  }
  .input-buscar i {
    left: 12px;
    font-size: 25px;
  }
  .input-buscar input {
    padding: 0 112px 0 50px;
  }
  .input-buscar .btn-buscar {
    right: 12px;
    font-size: 14px;
    padding: 8px 18px;
  }
}
