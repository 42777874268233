.ant-modal .ant-modal-content {
  // display: flex;
  // height: 100vh;
  // min-height: 100vh;
  justify-content: center;
  align-items: center;
  margin: 0px;
  padding: 10px;
  background: #fff; //linear-gradient(135deg, #71b7e6, #9b59b6);
}

.form-container {
  max-width: 700px;
  width: 100%;
  background: #fff;
  padding: 25px 30px;
  border-radius: 5px;
}

.form-container__title {
  font-size: 25px;
  font-weight: 500;
  position: relative;
}

.form-container__title::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  height: 3px;
  width: 30px;
  background: linear-gradient(135deg, #71b7e6, #9b59b6);
}

.form-container__details {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 20px 0 12px 0;
}

.form-container__details-input-box {
  margin-bottom: 15px;
  width: 100%; //calc(100% / 2 - 20px);
}

.form-container__details-input-box .datails {
  display: block;
  font-weight: 500;
  margin-bottom: 5px;
}

.form-container__details-input-box .input {
  height: 45px;
  width: 100%;
  outline: none;
  border-radius: 5px;
  border: 1px solid #ccc;
  padding-left: 15px;
  font-size: 16px;
  border-bottom-width: 2px;
  transition: all 0.3s ease;
}

.form-container__details-input-box .textarea {
  height: 100px;
  width: 100%;
  margin-bottom: 10px;
}

.form-container__details-input-box .input:focus {
  border-color: #9b59b6;
}

.form__button {
  height: 45px;
  // margin: 45px 0;
  width: 100%;
}

.form__button input {
  height: 100%;
  width: 100%;
  outline: none;
  color: #fff;
  border: none;
  font-size: 18px;
  font-weight: 500;
  border-radius: 6px;
  letter-spacing: 1px;
  background: linear-gradient(135deg, #71b7e6, #9b59b6);
}

.form__button input:hover {
  background: linear-gradient(-135deg, #71b7e6, #9b59b6);
}

@media (max-width: 584px) {
  .ant-modal .ant-modal-content {
    // display: flex;
    // height: 80vh;
    min-height: auto;
  }
  .form-container {
    max-width: 100%;
  }

  .form-container__details .form-container__details-input-box {
    margin-bottom: 15px;
    width: 100%;
  }

  .form-container__details {
    max-height: 300px;
  }

  .form-container__details-input-box .input {
    font-size: 14px;
  }

  .form__button {
    margin: 0px;
    width: 100%;
  }
}
