@import "../../../scss/index.scss";

.nacheros {
  min-height: 680px;
  display: block;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  padding: 0 20px;
  margin-bottom: 26px;
  background-color: $background-light;

  .text {
    margin: 10px;
    // inline-size: 650px;
    h3 {
      font-size: 18px;
      margin: 0px;
      font-weight: 700;
    }

    h2 {
      margin: 0px;
      font-size: 18px;
    }
    span {
      font-size: 20px;
      font-weight: 700;
    }

    h4 {
      margin-top: 5px;
      padding: 10px;
      border: 1px solid rgb(237, 95, 95);
      border-radius: 10px;
      font-size: 18px;
      font-weight: 600;
    }
  }

  .text-msg {
    margin: 10px;
    font-size: 14px;
    border: 1px solid rgb(225, 145, 145);
    border-radius: 5px;
    padding: 10px;
  }

  .input-box-nacheros {
    position: relative;
    height: 76px;
    max-width: 650px;
    width: 100%;
    background: $background-light;
    margin: 10px;
    border-radius: 8px;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);

    i,
    .button {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
    }

    i {
      left: 20px;
      font-size: 30px;
      color: #707070;
    }

    input {
      height: 100%;
      width: 100%;
      outline: none;
      font-size: 18px;
      font-weight: 400;
      border: none;
      padding: 0 155px 0 65px;
      background-color: transparent;
    }

    .button:active {
      transform: translateY(-50%) scale(0.98);
    }

    .button {
      right: 20px;
      font-size: 16px;
      font-weight: 400;
      color: #fff;
      border: none;
      padding: 12px 30px;
      border-radius: 6px;
      background-color: #4070f4;
      cursor: pointer;
    }
  }

  .resultado-nacheros {
    height: auto;
    //   width: 100%;
    margin: 10px;
    padding: 10px;
    border-radius: 8px;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
    background-color: $background-light-web;

    .box-info-nacheros {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(140px, 1fr));
      grid-gap: 24px;
      margin-top: 36px;

      li {
        padding: 24px;
        background: var(--light);
        border-radius: 20px;
        display: flex;
        align-items: center;
        grid-gap: 24px;
      }

      li .data-no {
        width: 100%;
        height: 80px;
        border-radius: 10px;
        font-size: 36px;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      li:nth-child(1) .data-no {
        background: $primary-color;
        color: $font-light;
      }

      li:nth-child(2) .data-no {
        background: $primary-color-dark;
        color: $font-light;
      }
      li .text h3 {
        font-size: 24px;
        font-weight: 600;
        color: $font-grey-dark;
      }
    }
  }

  .input-field-nacheros {
    display: flex;
    align-items: center;
    justify-content: left;
    margin: 10px;

    .btn-action {
      // padding: 12px 20px;
      width: 100%;
      height: 55px;
      margin: 0px 10px 0px 0px;
      padding: 5px;
      font-size: 20px;
      outline: none;
      text-align: center;
      border: none;
      background-color: #4070f4;
      color: #fff;
      border-radius: 6px;
      cursor: pointer;
      transition: 0.3s;
    }

    .btn-action:hover {
      background-color: #524b4b;
    }

    .btn-red {
      background: rgb(224, 16, 26);
    }

    .btn-red:hover {
      background: rgb(104, 17, 21);
    }
  }
}

.btn-action {
  // padding: 12px 20px;
  width: 100%;
  height: 55px;
  margin: 0px 10px 0px 0px;
  padding: 5px;
  font-size: 20px;
  outline: none;
  text-align: center;
  border: none;
  background-color: #4070f4;
  color: #fff;
  border-radius: 6px;
  cursor: pointer;
  transition: 0.3s;
}

.btn-action:hover {
  background-color: #524b4b;
}

/* que vamos hacer css */

.contenido-vamos {
  margin-top: 25px;
  .btnContent {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 0 20px;
    margin-bottom: 26px;
    .btn-box {
      display: flex;
      align-items: center;
      justify-content: center;
      width: calc(100% / 5 - 15px);
      background: rgb(0, 9, 94);
      padding: 15px 14px;
      border-radius: 12px;
      box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);

      a {
        color: white;
        font-size: 22px;
      }
    }
    .btn-red {
      background: rgb(224, 16, 26);
    }
  }
}

/* Responsive */
@media screen and (max-width: 500px) {
  .nacheros {
    padding: 0px;

    .input-field-nacheros {
      // border: 1px solid #4070f4;
      padding: 5px;
      margin: 5px;
    }

    .text {
      padding: 5px;
      margin: 5px;
      // inline-size: 650px;

      h3 {
        font-size: 18px;
        margin: 0px;
        font-weight: 700;
      }

      h2 {
        margin: 0px;
        font-size: 18px;
      }
      span {
        font-size: 20px;
        font-weight: 700;
      }

      h4 {
        margin-top: 5px;
        padding: 10px;
        border: 1px solid rgb(237, 95, 95);
        border-radius: 10px;
        font-size: 14px;
        font-weight: 400;
      }
    }

    .text-msg {
      margin: 10px;
      font-size: 18px;
      border: 1px solid rgb(225, 145, 145);
      border-radius: 5px;
      padding: 10px;
    }

    .input-box-nacheros {
      border: 1px solid #4070f4;
      margin: 10px;
      padding: 0px;
      width: auto;

      input {
        font-size: 26px;
        font-weight: 600;
        padding: 0 118px 0 54px;
      }

      i {
        left: 18px;
        font-size: 24px;
      }

      .button {
        font-weight: 600;
        padding: 7px 13px;
      }
    }

    .resultado-nacheros {
      border: 1px solid #4070f4;
      .box-info-nacheros {
        display: flex;
        // grid-template-columns: repeat(auto-fit, minmax(100%, 1fr));
        // grid-gap: 14px;
        // // margin-top: 26px;
        margin: 0px;

        li {
          // padding: 10px;
          background: var(--light);
          border-radius: 20px;
          display: inline-block;
          align-items: center;
          grid-gap: 10px;
        }

        // li .data-no {
        //   width: 100%;
        //   height: 90px;
        // }

        li .text h3 {
          font-size: 20px;
          font-weight: 600;
          color: $font-grey-dark;
        }
      }
    }
  }

  .btn-action {
    // padding: 12px 20px;
    font-size: 18px;
    padding: 10px;
  }

  .contenido-vamos {
    .btnContent {
      .btn-box {
        margin: 10px;
        width: 100%;
      }
    }
  }
}
