.consulta-container {
  min-height: 100vh;
  display: flex;
  // align-items: center;
  justify-content: center;
  background: #fff;
}

.consulta {
  position: relative;
  max-width: 1900px;
  width: 100%;
  border-radius: 6px;
  padding: 30px;
  margin: 0px;
  background-color: #fff;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
}

.consulta__search {
  margin: 0px;
  padding: 0px;
}

.resultado {
  margin: 25px;
  .text {
    h3 {
      font-size: 3em;
    }

    table {
      font-family: Arial, Helvetica, sans-serif;
      border-collapse: collapse;
      width: 100%;
    }

    td,
    th {
      border-bottom: 1px #dddddd solid;
      text-align: left;
      padding: 8px;
    }

    .striped:nth-child(even) {
      background-color: #dddddd;
    }
    .hover:hover {
      background-color: #61dafb;
    }
  }
}

@media (max-width: 750px) {
  .consulta-container form {
    overflow-y: scroll;
  }
  .consulta-container form::-webkit-scrollbar {
    display: none;
  }
  form .fields .input-field {
    width: calc(100% / 2 - 15px);
  }
}

@media (max-width: 550px) {
  .consulta {
    padding: 10px;
  }

  form .fields .input-field {
    width: 100%;
  }
}
