.overlay {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.contenido-modal {
  width: 800px;
  min-height: 100px;
  height: 90%;
  background: #fff;
  position: relative;
  border-radius: 5px;
  box-shadow: rbga(100, 100, 111, 0.2) 0px 7px 29px 0px;
  padding: 20px;
}

.encabezado-modal {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #160707;
}

.encabezado-modal h3 {
  font-weight: 500;
  font-size: 16px;
  color: #1766dc;
}

.btnCerrar-modal {
  position: absolute;
  top: 15px;
  right: 20px;

  width: 30px;
  height: 30px;
  border: none;
  background: none;
  cursor: pointer;
  transition: 0.3s ease all;
  border-radius: 5px;
  color: #1766dc;
}

.btnCerrar-modal:hover {
  background: #f2f2f2;
}

@media (max-width: 584px) {
  .contenido-modal {
    width: 90%;
  }
}
