.contenido-dashboard {
  margin-top: 25px;
  padding: 10px;
}

.nacheros-dashboard {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 0 20px;
  margin-bottom: 26px;

  .btnAgregar {
    padding: 12px 20px;
    width: 25%;
    font-size: 20px;
    outline: none;
    text-align: center;
    border: none;
    background-color: #4070f4;
    color: #fff;
    border-radius: 6px;
    cursor: pointer;
    transition: 0.3s;
  }
}

.table-contenido {
  margin: 10px;
  overflow-x: auto;
  table {
    width: 100%;
    border-collapse: collapse;
    box-shadow: 0 5px 10px #767b91;
    background-color: white;
    text-align: left;
    overflow: hidden;

    thead {
      box-shadow: 0 5px 10px #767b91;
    }

    th {
      padding: 1rem 2rem;
      text-transform: uppercase;
      letter-spacing: 0.1rem;
      font-size: 0.7rem;
      font-weight: 900;
    }

    td {
      padding: 1rem 2rem;
    }

    //Color alternating rows
    tr:nth-child(even) {
      background-color: #f4f6fb;
    }
  }
}

@media screen and (max-width: 768px) {
  .contenido-dashboard {
    margin: 0px;
  }

  .nacheros-dashboard {
    margin-bottom: 10px;
    padding: 0px;
    .btnAgregar {
      margin: 0px;
      width: 100%;
    }
  }

  .table-contenido {
    margin: 0px;
  }
  .hide {
    display: none;
  }
}
